import React from 'react';
const Header = () => {
    return(
        <div className="homepage-title-container">
            <h1 id="main-title">Independent Research Forum</h1>
            <h2>Where Investors Meet Expert Providers</h2>
            <p>We enable professional investors to access a wide range of high-quality Independent Research Providers, facilitating mutually beneficial relationships.</p>
        </div>

    );
}
export default Header;