import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "btn", "container" ]

  connect() {
    console.log("loading bar")
  }

  addLoadingBar() {
    console.log("add loading bar")
    this.containerTarget.classList.remove("d-none")
  }
}