import React from 'react';
import Scope from './Scope/Scope';
// import RichardHouse from './Richard/RichardHouse';


class Home extends React.Component {
    
    render() {
        const { logo } = this.props
        return(
            <div className="about-scope-container">
                {/* <About/> */}
                <Scope/>
                {/* <RichardHouse logo= {logo}/> */}
            </div>
        );
    }
}

export default Home;