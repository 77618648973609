import React from 'react';
import moment from 'moment';

const Cards = ({editions}, articles) => {

        // console.log(editions)
        return (
            <div className="cut-container">
                { editions.map((edition) => ( 
                    <a key={edition.number} id="cut-card-link" href={edition.link} target="_blank">
                        <div className="cut-card">
                            <div className="circle-container">
                                <div className="circle icon cut">
                                    <svg id='icon-circle-cut' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="50" cy="50" r="50"/>
                                    </svg>
                                    <svg id="icon-book" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="briefcase" className="svg-inline--fa fa-briefcase fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z"></path></svg>
                                </div>
                            </div>
                            <p id="edition-link">Edition {edition.number}</p>
                            <p id="edition-date">{moment(`${edition.date}`).format('Do MMM YY')}</p>
                        </div>
                    </a>
                ))}
            </div>
        )
}

export default Cards;