import React, { useState } from 'react';

const RequestTrialButton = ({ provider, edition }) => {
    
    const [toggleState, setToggleState] = useState("unclicked")
    
    return (
            <div className="">
      
                    <a className={toggleState === "clicked" ? "clicked" : "request-research-button"} 
                        href={"/research_provider/"+provider.id+"?edition="+edition.id}
                        onClick={() => setToggleState("clicked")}
                        >Request Research
                    </a>
          
                {toggleState === "clicked" ? <p>Thank you for requesting the research for {provider}. Our team will get back to you shortly.</p>: null}
            </div>
    )
}

export default RequestTrialButton;