import React from 'react';
import Mission from './Mission/Mission';
import Process from './Process/Process';
import Results from './Results/Results';
import Testimonials from './Testimonials/Testimonials';

class Investors extends React.Component {
    render() {
        const { description, testimonials, providers, list } = this.props
        return(
            <div className="investor-container">
                <div className="inner-container">
                    <Mission description= {description}/>
                    <Process/>
                    <Results type= "Providers" list= {list}/>
                    <p id='results-bottom-content'>
                        IRF introductions have led to <strong><a className="white_links" href="https://firebasestorage.googleapis.com/v0/b/irf-website-dashdevs.appspot.com/o/IRF%20Presentation%20-%20Subscriptions.pdf?alt=media&token=29260c61-ec95-4582-b55c-d7a152e74a25" target="_blank">Subscriptions</a></strong>,
                        <strong> <a className="white_links" href= "https://firebasestorage.googleapis.com/v0/b/irf-website-dashdevs.appspot.com/o/IRF%20Training%20sessions.pdf?alt=media&token=a09408fd-35b2-4d8a-a292-e048d5d1b40e" target="_blank">Training</a> </strong> 
                        and <strong><a className="white_links" href="https://firebasestorage.googleapis.com/v0/b/irf-website-dashdevs.appspot.com/o/IRF%20Bespoke%20Work.pdf?alt=media&token=2425e26f-cd66-4953-859c-18f0a71a9f89" target="_blank">Bespoke</a> </strong> work.
                        Sign up and check out our <strong><a className="white_links" href="/directory">Directory</a> </strong> 
                        for a full list of our Providers
                    </p>
                    <Testimonials testimonials= {testimonials} providers= {providers}/>
                </div>
            </div>
        );
    }
}

export default Investors;
