import React from 'react'; 
import "animate.css/animate.min.css";
// import ScrollAnimation from 'react-animate-on-scroll';

const ProcessProviders = () => {
    return(
        <div className="process-container">
            <div className="process-timeline">
                <h2>Process</h2>
                <hr className="red-divider"/>
                <ul className="process-list">
                    <li>IRF carries out due diligence on Providers to ensure they are Independent, Differentiated and High Quality to guarantee they make an attractive proposition to investors. If these three criteria are met, IRF invites the Provider to join its suite.</li>
                    <li>As a broker of research, IRF’s fees are met by the research providers through an ongoing 20% revenue share agreement for successful introductions.</li>
                    <li>IRF builds a detailed profile of each investor. This understanding means that introductions are well matched.</li>
                    <li>Introductions are made through one-on-one meetings, calls, IRF-sponsored events and through The Cut.</li>
                    <li>All introductions are compliant with MiFID II requirements.</li>
                </ul>
            </div>
        </div>
    );
}

export default ProcessProviders;