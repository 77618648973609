import React from 'react';

const Scope = () => {
    return(
        <div className="scope-container">
            {/* <h2 className="scope-header">Our Scope</h2> */}
            {/* <hr className="red-divider home-divider"/> */}
            <div className="scope-dropdown">
                <div className="asset-classes-drop drop">
                    <div className="dropdown-icons">
                        <div className="circle icon">
                            <svg id='icon-circle' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50"/>
                            </svg>
                            <svg id="icon-briefcase" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="briefcase" className="svg-inline--fa fa-briefcase fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z"></path></svg>
                        </div>
                        <h2 className="scope-title">Asset Classes</h2>
                    </div>
                    <div className="dropdown-content">
                        <li><a href="directory?search_directory=&asset%5BCommodities%5D=on&button=" className="normal-links">Commodities</a></li>
                        <li><a href="directory?search_directory=&asset%5BCorporate+Credit%5D=on&button=" className="normal-links">Corporate Credit</a></li>
                        <li><a href="directory?search_directory=&asset%5BCryptocurrency%5D=on&button=" className="normal-links">Cryptocurrency</a></li>
                        <li><a href="directory?search_directory=&asset%5BDistressed+Debt%5D=on&button=" className="normal-links">Distressed Debt</a></li>
                        <li><a href="directory?search_directory=&asset%5BEquities%5D=on&button=" className="normal-links">Equities</a></li>
                        <li><a href="directory?search_directory=&asset%5BETF%5D=on&button=" className="normal-links">ETFs</a></li>
                        <li><a href="directory?search_directory=&asset%5BFx%5D=on&button=" className="normal-links">FX</a></li>
                        <li><a href="directory?search_directory=&asset%5BFixed+Income%5D=on&button=" className="normal-links">Fixed Income</a></li>
                        <li><a href="directory?search_directory=&asset%5BMacro%5D=on&button=" className="normal-links">Macro</a></li>
                        <li><a href="directory?search_directory=&asset%5BOther%5D=on&button=" className="normal-links">Other</a></li>
                        <li><a href="directory?search_directory=&asset%5BPrivate+Equity%5D=on&button=" className="normal-links">Private Equity</a></li>
                    </div>
                </div>
                <div className="methodologies-drop drop">
                    <div className="dropdown-icons">
                        <div className="circle icon">
                            <svg id='icon-circle' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50"/>
                            </svg>
                            <svg id="icon-briefcase" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="flask" className="svg-inline--fa fa-flask fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M437.2 403.5L320 215V64h8c13.3 0 24-10.7 24-24V24c0-13.3-10.7-24-24-24H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h8v151L10.8 403.5C-18.5 450.6 15.3 512 70.9 512h306.2c55.7 0 89.4-61.5 60.1-108.5zM137.9 320l48.2-77.6c3.7-5.2 5.8-11.6 5.8-18.4V64h64v160c0 6.9 2.2 13.2 5.8 18.4l48.2 77.6h-172z"></path></svg>                        </div>
                        <h2 className="scope-title">Methodologies</h2>
                    </div>
                    <div className="dropdown-content">
                        <li>Alternative Data</li>
                        <li>Bespoke</li>
                        <li>Forensic Accounting</li>
                        <li>Fundamental</li>
                        <li>Macro</li>
                        <li>Primary</li>
                        <li>Quantitative</li>
                        <li>Technical</li>
                    </div>
                </div>
                <div className="industries-drop drop">
                    <div className="dropdown-icons">
                        <div className="circle icon">
                            <svg id='icon-circle' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50"/>
                            </svg>
                            <svg id="icon-briefcase" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-pie" className="svg-inline--fa fa-chart-pie fa-w-17" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544 512"><path fill="currentColor" d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"></path></svg>                        </div>
                        <h2 className="scope-title">Industries</h2>
                    </div>
                    <div className="dropdown-content">
                        <li>Consumer Discretionary</li>
                        <li>Consumer Staples</li>
                        <li>Energy</li>
                        <li>Financials</li>
                        <li>Health Care</li>
                        <li>Industrials</li>
                        <li>Materials</li>
                        <li>Real Estate</li>
                        <li>Technology</li>
                        <li>Telecommunications</li>
                        <li>Utilities</li>
                    </div>
                </div>
                <div className="regions-drop drop">
                    <div className="dropdown-icons">
                        <div className="circle icon">
                            <svg id='icon-circle' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50"/>
                            </svg>
                            <svg id="icon-briefcase" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="globe-africa" className="svg-inline--fa fa-globe-africa fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm160 215.5v6.93c0 5.87-3.32 11.24-8.57 13.86l-15.39 7.7a15.485 15.485 0 0 1-15.53-.97l-18.21-12.14a15.52 15.52 0 0 0-13.5-1.81l-2.65.88c-9.7 3.23-13.66 14.79-7.99 23.3l13.24 19.86c2.87 4.31 7.71 6.9 12.89 6.9h8.21c8.56 0 15.5 6.94 15.5 15.5v11.34c0 3.35-1.09 6.62-3.1 9.3l-18.74 24.98c-1.42 1.9-2.39 4.1-2.83 6.43l-4.3 22.83c-.62 3.29-2.29 6.29-4.76 8.56a159.608 159.608 0 0 0-25 29.16l-13.03 19.55a27.756 27.756 0 0 1-23.09 12.36c-10.51 0-20.12-5.94-24.82-15.34a78.902 78.902 0 0 1-8.33-35.29V367.5c0-8.56-6.94-15.5-15.5-15.5h-25.88c-14.49 0-28.38-5.76-38.63-16a54.659 54.659 0 0 1-16-38.63v-14.06c0-17.19 8.1-33.38 21.85-43.7l27.58-20.69a54.663 54.663 0 0 1 32.78-10.93h.89c8.48 0 16.85 1.97 24.43 5.77l14.72 7.36c3.68 1.84 7.93 2.14 11.83.84l47.31-15.77c6.33-2.11 10.6-8.03 10.6-14.7 0-8.56-6.94-15.5-15.5-15.5h-10.09c-4.11 0-8.05-1.63-10.96-4.54l-6.92-6.92a15.493 15.493 0 0 0-10.96-4.54H199.5c-8.56 0-15.5-6.94-15.5-15.5v-4.4c0-7.11 4.84-13.31 11.74-15.04l14.45-3.61c3.74-.94 7-3.23 9.14-6.44l8.08-12.11c2.87-4.31 7.71-6.9 12.89-6.9h24.21c8.56 0 15.5-6.94 15.5-15.5v-21.7C359.23 71.63 422.86 131.02 441.93 208H423.5c-8.56 0-15.5 6.94-15.5 15.5z"></path></svg>                        </div>
                        <h2 className="scope-title">Regions</h2> 
                    </div>
                    <div className="dropdown-content">
                        <li>Developed</li>
                        <li>Emerging</li>
                        <li>Frontier</li>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Scope;