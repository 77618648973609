const directory = () => {
    const directoryContainer = document.querySelector(".directory-container")
    
    if (directoryContainer) {
        const searchButton = document.querySelector(".directory-form")
        const resetButton = document.querySelector(".btn-dindex")
        searchButton.addEventListener("submit", () => {
            if (resetButton.classList.contains('directory-disappear')) {
                resetButton.classList.remove('directory-disappear');
                } else resetButton.classList.add('directory-disappear');
        })
        
    }
}

export {directory};

// window.addEventListener('turbolinks:load', () => {
//     intro.classList.add('directory-disappear');
// })