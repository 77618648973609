const editionScroll = () => {
    const editionContainer = document.querySelector("#edition-view-container")
    const subheadings = document.querySelectorAll(".subheading-contents")
    const article = document.getElementById('article_scroll_id')
    
    if (article) {
        var myArticle = document.getElementById(`article-${article.innerText}`)
    }
    
    // document.addEventListener('DOMContentLoaded', () => {
    if (myArticle) {
        setTimeout(() => {
            var topPosArticle = myArticle.offsetTop;
            window.scrollTo(0, topPosArticle - 200);
        }, 500)
    }
    // })

    if (subheadings) {
        subheadings.forEach((subheading) => {
            subheading.addEventListener('click', function(event) {
                var valueTarget = (event.target.id)
                var myElement = document.getElementById(`subheading-${valueTarget}`)
                var topPos = myElement.offsetTop;
                window.scrollTo(0, topPos - 200);
                subheadings.forEach((subheadingSub) => {
                    subheadingSub.classList.remove('red-highlight')
                })
                event.target.classList.add('red-highlight')
            })
        })
    }

}

export { editionScroll };