import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]
  
  initialize() {
    this.active = true
  }

  connect() {
    let counter = 1
    setInterval(() => {
      if (this.active) {
        this.containerTarget.scroll(0, counter)
        counter += 1
      } else if (!this.active) {
        counter = this.containerTarget.scrollTop 
      } 
    }, 70)
  }

  deactivate() {
    this.active = false
  }

  activate() {
    this.active = true
  }
}