import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "categories" ]

  connect() {}

  toggle() {
    this.categoriesTarget.classList.toggle("d-none")
  }
}