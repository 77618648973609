const profileButton = () => {
    const profile = document.querySelector('#profile-button')
    const profiledrop = document.querySelector('.logged-in-info')
    if (profile) {
        profile.addEventListener("click", () => {
            if (profiledrop.classList.contains('display-toggle')) {
                profiledrop.classList.remove('display-toggle');
            } else profiledrop.classList.add('display-toggle')
        })
    }  
}

export { profileButton }