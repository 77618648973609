import mixpanel from 'mixpanel-browser';
mixpanel.init('a015b7f8f6370c516dc618aa1d1a8ac0');
const cutHeadings = () => {
    const cutContainer = document.querySelector(".edition-container")
    if (cutContainer) {
        const headings = document.querySelectorAll(".tracker-information")
        headings.forEach(function(item) {
            item.addEventListener('click', function() {
                const tag = item.innerHTML
                
                const userid = document.cookie.split('; ')[1].split('=')[1]
                // console.log(userid)
                
                
                mixpanel.people.set(`${userid}`, {
                'ID': `${userid}`
                
                });
                mixpanel.identify(`${userid}`);
                mixpanel.track(`The Cut clickthrough: ${tag}`);
                // mixpanel.track(`${userid}`, {
                //     'ID': `${userid}`
                  
                //   });
                  
                
            })
        })
    }
}

export { cutHeadings };