const richardButton = () => {
    const richardButton = document.querySelector('#container-richard')
    const richardDescription = document.querySelector('#description-richard')
    if (richardButton) {
        richardButton.addEventListener("click", () => {
            console.log('Hello')
            if (richardDescription.classList.contains('display-toggle')) {
                richardDescription.classList.remove('display-toggle');
            } else richardDescription.classList.add('display-toggle')
        })
    }  
}

export { richardButton }