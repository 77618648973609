import React from "react";
// import video from "./cityVideo.mp4";
// import image from "./Assets/image.jpg"

const Main = (props) => {
  return(
    <div className="homepage-container">
      <video 
        className="homeVideo"
        loop 
        muted 
        playsInline 
        autoPlay 
        src={props.video}
        type='video/mp4' 
        preload='auto'
      />
    </div>
  );
}

export default Main;