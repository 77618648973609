import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "container", "input" ]

    connect() {
       console.log("Providers list!")
    }

    findProviders(event) {
      console.log(event.target.value)
      
      fetch(`/providers/list?query=${event.target.value}`, {
        method: 'GET',
        headers: {'Accept': 'text/plain' }
      })
      .then(response => response.text())
      .then((data) => {
        this.containerTarget.classList.remove("d-none")
        this.containerTarget.innerHTML = data
      })
    }

    setProvider(event) {
      this.inputTarget.value = event.currentTarget.innerText
    }

    closeList() {
      this.containerTarget.classList.add("d-none")
    }
}