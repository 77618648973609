import React from "react";
import "animate.css/animate.min.css";
// import ScrollAnimation from 'react-animate-on-scroll';

const Process = () => {
  return (
    <div className="process-container">
      <div className="process-timeline">
        <h2>Process</h2>
        <hr className="red-divider" />
        <ul className="process-list">
          <li>
            IRF carries out due diligence on providers to ensure they possess
            differentiating features and will make an attractive proposition to
            investors.
          </li>
          <li>
            IRF builds a detailed profile of each investor. This understanding
            means that introductions are well matched.
          </li>
          <li>
            Introductions are made through one-on-one meetings, calls,
            IRF-sponsored events and through The Cut.
          </li>
          <li>All introductions are compliant with MiFID II requirements.</li>
          <li>
            As a broker of research, IRF’s fees are met by the research
            providers.
          </li>
          <li>
            Investors benefit from tailored advice on research at no additional
            cost.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Process;
