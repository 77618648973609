const toggleFilters = () => {
    const filters = document.querySelector(".filter-drop");
    var btn = document.querySelector('.filters-button');
    
    if (filters) {
        if (window.window.innerWidth < 768) {filters.style.display = 'none'}

        btn.addEventListener('click', () => {
            if (filters.style.display == 'none') {
                filters.style.display = 'block'
            } else {
                filters.style.display = 'none'
            }
        });
    }
}

const showCheckboxes = (select_class, check_class) => {
    var expanded = false;
    const selectBox = document.querySelector(`.${select_class}`);
    var checkboxes = document.querySelector(`.${check_class}`);
    var allCheckBoxes = document.querySelectorAll(".all-checkboxes");
    
    if (checkboxes) {
        checkboxes.style.display = "none"
            
        selectBox.addEventListener('click', () => {
            console.log('heyoooooooooooooooooooooooooooooooo')
            if (checkboxes.style.display == "none") {
                allCheckBoxes.forEach((checkbox) => {
                    checkbox.style.display = 'none';
                })
                checkboxes.style.display = "block";
                // expanded = true;
            } else {
                checkboxes.style.display = "none";
                // expanded = false;
            }
        });
    }
}

const insertProviders = () => {
  const scrollContainer = document.querySelector('.directory-results-container');
  const tableBody = document.querySelector('#table-body');
  const form = document.querySelector('.providers-dir-form');
  const providers = document.querySelectorAll('.providers-row');
  const providersMobileContainer = document.querySelector('.providers-mobile');
  let scrolLevel = 0;  
  let hitCounter = 0;
  let switchTracker = true;
  
  if(scrollContainer) {
    scrollContainer.addEventListener("scroll", () => {
      scrolLevel = scrollContainer.scrollTop / (scrollContainer.scrollHeight - scrollContainer.clientHeight)

      if(scrolLevel > 0.9 && providers.length < tableBody.dataset['count'] && switchTracker ) {
        switchTracker = false;
        hitCounter += 1
        
        if(window.innerWidth > 768) {
          let url = `${form.action}?counter=${hitCounter}`
          fetch(url, { headers: {'Accept': 'text/plain' }})
          .then(response => response.text())
          .then((data) => {
              tableBody.insertAdjacentHTML("beforeend", data)
              switchTracker = true;
          })
        } else {
          let url = `${form.action}?counter=${hitCounter}&mobile=true`
          fetch(url, { headers: {'Accept': 'text/plain' }})
          .then(response => response.text())
          .then((data) => {
              providersMobileContainer.insertAdjacentHTML("beforeend", data)
              switchTracker = true;
          })
        }
      }
    })
  }
}

export { showCheckboxes, insertProviders, toggleFilters }