import React from 'react';
import Chevron from '../Chevron/Chevron'

const RichardHouse = (props) => {
    return(
        <div className="richard-container" id="container-richard">
            <div className="richard-description" id="description-richard">
                <p>IRF is pleased to be supporting the Richard House Children’s Hospice which provides palliative care in East London for children with life-limited illnesses. If you would like to learn more about the incredible work that Richard House does, please do visit their website.</p>
            </div>
            <div className="richard-logo">

            <a className="richard-link" href="https://www.richardhouse.org.uk/">
                <img src={props.logo} alt="richard-house-logo"/>
                <Chevron id="richard-chevron"/>
            </a>
            </div>
        </div>
    );
}

export default RichardHouse;