import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "loadingBar" ]
        
    initialize() {
      this.hitCounter = 0
      this.switchTracker = true
    }

    connect() {
      console.log("Research scroll controller!")
    }

    scroll(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)
      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1

        this.loadingBarTarget.classList.toggle("d-none")
        let url = `/portal?counter=${this.hitCounter}`

        if(this.element.dataset.searchQuery != '[]' && this.element.dataset.searchQuery != '') {   
          url = window.location.href + `&counter=${this.hitCounter}`
        }

        console.log(window.location.href)
        //let url = window.location.href
        // let url = `${this.element.dataset.searchQuery}&counter=${this.hitCounter}` 
        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }
}