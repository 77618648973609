const stockSearch = () => {
    const pageContainer = document.getElementById('stock-search-ajax');
    const titles = document.querySelectorAll('stock-providers-titles');
    if(pageContainer) {
        console.log('Hello from stockSearch');
    
        titles.forEach((title) => { 
            title.addEventListener('click', (event) => {
                console.log('Hello from title');
                const title = event.currentTarget.innerText;

                const url = ``;
                fetch(url)
                .then(response => response.text())
                .then((data) => {
                    pageContainer.innerHTML = data;
                });
            });
        });
    }
}

export { stockSearch }