const stripePay = () => {
    const paymentButton = document.getElementById('pay');
    if(paymentButton) {
        paymentButton.addEventListener('click', () => {
            const stripe = Stripe('pk_live_51M4i9DFNwLpMQNqy417HsoPrgDj24lJZREn4NoRywYpUVGxqWYzUnXlNrSQf6GbFeVvQTwHpbJXtUHjiqC5Nisd500uZMYQ2GC');
            stripe.redirectToCheckout({
                sessionId: paymentButton.dataset.sessionId
            });
        });
    }
}

export { stripePay }