const menuButton = () => {

const burger = document.querySelector('.burger')
const dropdown = document.querySelector(".navbar-collapse")
const lineTop = document.getElementById("line-top");
const lineMiddle = document.getElementById("line-middle");
const lineBottom = document.getElementById("line-bottom");
// Get all buttons with class="btn" inside the container

  if(burger) {
    burger.addEventListener("click", () => {
        if (lineTop.classList.contains('line-top-active')) {
        lineTop.classList.remove('line-top-active');
        } else lineTop.classList.add('line-top-active');
    });
    
    burger.addEventListener("click", () => {
        if (lineMiddle.classList.contains('line-middle-active')) {
        lineMiddle.classList.remove('line-middle-active');
        } else lineMiddle.classList.add('line-middle-active');
    });
    
    burger.addEventListener("click", () => {
        if (lineBottom.classList.contains('line-bottom-active')) {
        lineBottom.classList.remove('line-bottom-active');
        } else lineBottom.classList.add('line-bottom-active');
    });  
  
    burger.addEventListener("click", () => {
      if (dropdown.classList.contains('show')) {
      dropdown.classList.remove('show');
      } else dropdown.classList.add('show');
    });
  }
}



export { menuButton }
