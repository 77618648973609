
const imgPopUp = () => {
    const articleImages = document.querySelectorAll(".article-img")
    const w = window.innerWidth
    articleImages.forEach((img) => {
        img.style.width = "50vh"
        img.parentNode.addEventListener('click', () => {
            if(w > '768') {
                img.parentNode.removeChild(img.nextSibling)
                if(img.nextSibling) {
                    img.parentNode.removeChild(img.nextSibling)
                }

                if(img.style.width == '50vh') {
                    img.style.width = '55vw'
                    // img.parentNode.removeChild(img.nextSibling)
                    // img.parentNode.removeChild(img.nextSibling)
                    var fontAwesomeIcon = document.createElement('i');
                    fontAwesomeIcon.classList.add("fa-solid")
                    fontAwesomeIcon.classList.add("fa-compress")
                    fontAwesomeIcon.classList.add("enlarge-icons")
                    img.insertAdjacentElement('afterend', fontAwesomeIcon);
                } else {
                    img.style.width = '50vh'
                    // img.parentNode.removeChild(img.nextSibling)
                    // img.parentNode.removeChild(img.nextSibling)
                    var fontAwesomeIcon = document.createElement('i');
                    fontAwesomeIcon.classList.add("fa-solid")
                    fontAwesomeIcon.classList.add("fa-expand")
                    fontAwesomeIcon.classList.add("enlarge-icons")
                    img.insertAdjacentElement('afterend', fontAwesomeIcon);
                }
            }
        });
    })
} 

export { imgPopUp }