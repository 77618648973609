import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addedList", "companiesList"]

  connect() { console.log('Stocks, controller!') }

  addStock(event) {
    const stock = event.currentTarget.firstElementChild.innerText
    fetch(`/add_stock_query/${stock}`)    

    const html = `<div class="d-flex justify-content-between w-50">
                    <p class="m-0" style="font-size: 12px;">${stock}</p>
                    <p style="cursor: pointer;" class="m-0" data-action="click->update#removeStockAsset click->stocks#removeStock">x</p>
                  </div>
                 `
    this.addedListTarget.insertAdjacentHTML('beforeend', html)
  }

  removeStock(event) {
    const stock = event.currentTarget.previousSibling.previousSibling.innerText
    event.currentTarget.parentElement.outerHTML = ""
    fetch(`/remove_stock/${stock}`)    
  }
  
  stocksAutocomplete(event) {
    const html = `<div class="scroll-div" style="margin: auto; height: 100px; background-color: rgba(0,0,0,0.1); padding: 5px; margin-top: 10px;">
                    <div style="margin: 5px auto 0 auto; width: 10vw; text-align: center;">
                      <div class="spinner-border" role="status" style="width: 4vw; height: 4vw;">
                        <span class="visually-hidden"></span>
                      </div>
                    </div>
                    <p style="margin: 5px auto 0 auto; width: 10vw; text-align: center;">LOADING STOCKS</p>
                  </div>`

    this.companiesListTarget.innerHTML = html 
                            
    const url = `/portfolio?query=${event.currentTarget.value}`
    fetch(url, { method: 'GET', headers: {'Accept': 'text/plain' } })
    .then(response => response.text())
    .then(data => {
      this.companiesListTarget.innerHTML = data
    })
  }
}

