import React, { useState } from "react";
import moment from "moment-timezone";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleState: "unclicked",
    };
  }

  render() {
    const { event, eventspeakers, user, providers } = this.props;
    const { toggleState } = this.state;
    const currentDate = new Date();
    const eventDate = new Date(event.date);
    const topic_list = [];
    
    if (event.topics) {
      event.topics.includes(";")
        ? event.topics.split(";").map((topic) => {
            topic_list.push(topic);
          })
        : topic_list.push(event.topics);
    } else {
      topic_list.push(""); 
    }

    const audioCheck = (event) => {
      if (event.audio) {
        return (
          <a href={event.audio} className="btn" target="_blank">
            Audio
          </a>
        );
      }
    };

    const summaryCheck = (event) => {
      if (event.summary) {
        return (
          <a
            data-toggle="modal"
            className="btn"
            data-target="#summary-modal"
            datahref="modal"
            target="_blank"
          >
            Summary
          </a>
        );
      }
    };

    const transcriptCheck = (event) => {
      if (event.transcript) {
        return (
          <a href={event.transcript} className="btn" target="_blank">
            Transcript
          </a>
        );
      }
    };

    const slidesCheck = (event) => {
      if (event.slides) {
        return (
          <a href={event.slides} className="btn" target="_blank">
            Slides
          </a>
        );
      }
    };
    
    const userCheck = (user) => {
      if (user) {
        return (
          <div className="buttons-show-container">
            {summaryCheck(event)}
            {transcriptCheck(event)}
            {audioCheck(event)}
            {slidesCheck(event)}
          </div>
        );
      }
    };

    const photoCheck = (eventspeakers) => {
      console.log(eventspeakers);
      if (eventspeakers.length > 0) {
        if (eventspeakers[0].photo) {
          return eventspeakers.map((speaker) => (
            <div className="speaker-card-photo">
              {photoConfirm(speaker)}
              <p className="list-no-dec">{speaker.full_name}, {speaker.org}</p>
            </div>
          ));
        } else if(eventspeakers.length == 1) {
          return (
            <ul className="topic-list">
              {eventspeakers.map((speaker) => (
                <li className="list-no-dec">{speaker.full_name}</li>
                ))}
            </ul>
          );
        } else if(eventspeakers[0].org == eventspeakers[eventspeakers.length -1].org) {
          return (
            <ul className="topic-list">
              {eventspeakers.map((speaker) => (
                <li className="list-no-dec">{speaker.full_name}</li>
                ))}
            </ul>
          );
        } else {
          return (
            <ul className="topic-list">
              {eventspeakers.map((speaker) => (
                <li className="list-no-dec">{speaker.full_name}, {speaker.org}</li>
                ))}
            </ul>
          );
        }
      } else {
        return (
          <ul>
            <li>Speakers TBC</li>
          </ul>
        );
      }
    };
    
    const photoConfirm = (speaker) => {
      if (speaker.photo) {
        return (
          <img src={speaker.photo} alt="speaker" className="speaker-image" />
          );
        } else {
          return (
          <div className="speaker-image">
            <i className="fas fa-bullhorn"></i>
          </div>
        );
      }
    };
    
    const detailsCheck = (topic_list, event, user) => {
      if (user) {
        if (event.custom) {
          return (
            <>
              <div className="show-description event-topics-container">
               
                <div className="custom-content" style={{ fontSize: "15px" }}>
                  {ReactHtmlParser(event.custom)}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="show-description event-topics-container">
                <ul className="topic-list">
                  {topic_list.map((topic) => (
                    <li key={topic}>{ReactHtmlParser(topic)}</li>
                    ))}
                </ul>
              </div>
            </>
          );
        }
      }
    };

    const requestTrial = () => {
      if (!event.custom) {
        <div
        className={toggleState === "clicked" ? "clicked" : "event-buttons"}
        >
          <a
            href={"/request_trial/" + event.id}
            onClick={() => this.setState({ toggleState: "clicked" })}
            className="btn"
            >
            Request Trial
          </a>
        </div>;
      }
    };
    
    return (
      <React.Fragment>
        <div className="event-show-container">
          <div className="event-show-container-info">
            <div className="event-show-times"></div>

            {detailsCheck(topic_list, event, user)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Event;
