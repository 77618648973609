import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    console.log("Auto Suggest")
  }

  findProviders(event) {
    fetch(`/directory?search_directory=${event.target.value}&button=&providers=0&companies=0`, {
      method: 'GET',
      headers: {'Accept': 'text/plain' }
    })
    .then(response => response.text())
    .then((data) => {
      this.containerTarget.innerHTML = data
    })
  }
}