
const searchButton = () => {
    
    const search = document.querySelector('#magnify')
    const searchLink = document.querySelector('#search-link')
    const searchbar = document.querySelector('.search-website')
    if (search) {
        search.addEventListener("click", () => {
            if (searchbar.classList.contains('display-toggle')) {
                searchbar.classList.remove('display-toggle');
            } else searchbar.classList.add('display-toggle')
        })
    }
    if (searchLink) {
        searchLink.addEventListener("click", () => {
            if (searchbar.classList.contains('display-toggle')) {
                searchbar.classList.remove('display-toggle');
            } else searchbar.classList.add('display-toggle')
        })
    }

    // }
}

export { searchButton }