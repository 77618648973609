import React from "react";

const Mission = (props) => {
  return (
    <div className="mission-container">
      <div className="mission-title">
        <h2>Mission</h2>
      </div>
      <hr className="red-divider" />
      <div className="mission-description">
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default Mission;
