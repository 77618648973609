import React from "react";
import Mission from "./Mission/Mission";
import ProcessProviders from "./Process/ProcessProviders";
import Results from "./Results/Results";
import Testimonials from "./Testimonials/Testimonials";

class Providers extends React.Component {
  render() {
    const { description, testimonials, providers, list } = this.props;
    let testi;
    if (testimonials) {
      testi = <Testimonials testimonials={testimonials} />;
    } else {
      testi = <hr />;
    }

    return (
      <div className="providers-container">
        <div className="inner-container">
          <Mission description={description} />
          <ProcessProviders />
          <Results type="Investors" list={list} />
          <div className="directory-note-container">
            <p>
              For information on all our Research Providers, please head to our
              Directory of Providers.
            </p>
            <a
              href="/directory"
              class="btn btn-directory btn-directory-providers"
            >
              Directory of Providers
            </a>
          </div>
          {testi}
        </div>
      </div>
    );
  }
}

export default Providers;
