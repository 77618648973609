import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class Testimonials extends Component {
    render() {
        const {testimonials} = this.props
        return(
            <div className="results-container">
                <div className="testimonials-title">
                    <h2>Testimonials</h2>
                <hr className="red-divider"/>
                </div>
                <div className="testi-container">
                    <Carousel>
                    {testimonials.map((testimonial) => 
                    <div className="carousel-content"> 
                        
                            <section className="testibox" key={testimonial.id} id={testimonial.id}>
                                <div className="testicontent">
                                    <i className="fas fa-quote-left quote" id="quote-left"></i>
                                    <p>{testimonial.content}</p>
                                    <i className="fas fa-quote-right quote" id="quote-right"></i>
                                </div>
                                <div className="testiperson">
                                    <p id="person-title-testi">{testimonial.title.toUpperCase()}</p>
                                    <p id="company-name-testi">{testimonial.organisation}</p>
                                </div>
                           </section>
                        
                    </div>
                    )}
                    </Carousel>
                </div>
            </div>
        )
    }
}

export default Testimonials;