import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "loadingBar" ]
        
    initialize() {
      this.hitCounter = 0
      this.switchTracker = true
    }

    connect() {
      console.log("infinite scroll")
    }

    scroll(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)
      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1

        this.loadingBarTarget.classList.toggle("d-none")

        let url = `/providers/list_all?counter=${this.hitCounter}`

        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }
}