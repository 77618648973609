import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "checkbox", "stockAsset", "stockList"]
  connect() { console.log('Update, controller!') }

  // submit form when a checkbox is clicked
  submitForm(reset) {
    // if reset true then add a reset param
    const url = reset == "true" ? this.formTarget.action + '?reset=true' : this.formTarget.action
    fetch(url, {
      method: "POST",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.formTarget)
    })
    .then(response => response.text())
    .then((data) => {
      this.categoriesTarget.innerHTML = data
      // if(reset == "true") { location.reload() }
    })
  }

  removeCategory(event) {
    const class_name = event.currentTarget.getAttribute('data-class')
    const category = event.currentTarget.getAttribute('data-category')
    const url = `remove_category/${class_name}/${category}`
    let checkbox = null
    // find checkbox container that has the same text as the category clicked 
    this.checkboxTargets.forEach((target) => {
      if(target.children[1].innerText == category) { checkbox = target }
    })
    // call remove category endpoint and display updated assets container
    fetch(url, { headers: { "Accept": "text/plain" }})
    .then(response => response.text())
    .then((data) => {
        this.categoriesTarget.innerHTML = data
        if(checkbox) { checkbox.children[0].checked = false}
    })
  }

  removeProvider(event) {
    const id = event.currentTarget.getAttribute('data-id')
    const title = event.currentTarget.getAttribute('data-title')
    const status = event.currentTarget.getAttribute('data-status')
    // call add research endpoint with status unfollow to remove provider that was clicked
    const url = `/add_research/${id}?status=${status}&query=${title}&ajax=true`
    fetch(url, {
      headers: { "Accept": "text/plain" }
    })
    .then(response => response.text())
    .then((data) => {
      this.categoriesTarget.innerHTML = data
    })
  }

  resetForm(event) {
    const checkBoxes = document.querySelectorAll("#analysts_den_form input")
    checkBoxes.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.submitForm("true")
    this.formTarget.submit()
  }

  removeStockAsset(event) {
    const stock = event.currentTarget.previousSibling.previousSibling.innerText
    this.stockAssetTargets.forEach((stockAsset) => {
      if(stock == stockAsset.innerText) { stockAsset.outerHTML = "" }
    })
  }

  addStockAsset(event) {
    const stock = event.currentTarget.innerText
    const html = `<span class="btn btn-info" data-update-target="stockAsset" style="padding: 2px 3px;">
                    ${stock}
                  </span> 
                  <small style="cursor: pointer;" data-action="click->update#removeStockAssetFromList">X</small>`
    this.stockListTarget.insertAdjacentHTML('beforeend', html)
  }

  removeStockAssetFromList(event) {
    const stock = event.currentTarget.previousSibling.previousSibling 
    fetch(`/remove_stock/${stock.innerText}`)     
    stock.outerHTML = ""
    event.currentTarget.outerHTML = ""
  }
}
