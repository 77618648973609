import React from 'react';

const Title = (props) => {
    
    return(
        <div className="titlebanner" style={{padding: "0px", textAlign: "center", width: "100%"}}>
            <h1 style={{padding: "0px", textAlign: "center"}}>{props.title}</h1>
        </div>
    );
}

export default Title