import React from 'react';

const Results = ({type, list}) => {
    return( 
        <div className="results-container">
            <div className="results-title">
                <h2>Results</h2>
            </div>
            <hr className="red-divider"/>
            <div className="results-description">
                <p>IRF have set up successful introductions to a wide range of {type}, such as</p>
                <ul>
                    {list.map((item) => 
                        <li>{item}</li>
                    )}
                </ul>
                
                {/* <div className="word w2">World Renowned Economists</div>
                <div className="word w3">Sector Specialists</div> */}
                
            </div>
        </div>
    );
}

export default Results;