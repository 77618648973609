import { number } from 'prop-types';
import React from 'react';
import CountUp from 'react-countup';

const CountTwo = ({numbers}) => {
    return(
        <div className="homepage-numbers-two position-relative bottom-0">
            {numbers.map((n) => (
                
            <div className="providers-numbers" key={n.title}>
                <CountUp className='counter' start={0} duration={2.5} end={n.number} />
                <p>{n.title}</p>
            </div>
            
            ))}
        </div>
    );
}

export default CountTwo;